export const MENU_PERMISSIONS =
{
    admin: {      
        scopes: [
            //main menu
            'dashboard_read',
            
            //DC replenishment
            'dcreplenishment_read',
            'vendororder_read', 'replenishment_approve', 'replenishment_reject', 'replenishment_refresh', 'replenishment_manualtrigger',
            'vendorreceipt_read', 'vendororders_delete',
            'vendorbackorders_read', 'pendingbackorder_cancel',

            //Frame arranger
            'framearranger_read',
            'vendorstocollectionsmapping_read', 'vendorscollectionsmapping_add', 'vendorscollectionsmapping_edit',
            'assortmentmanagement_read', 'assortmentmanagement_add', 'assortmentmanagement_delete', 'assortmentmanagement_clone', 'assortmentmanagement_import',
            'officeplanograms_read', 'officecollectionsmapping_add', 'officecollectionsmapping_edit', 'officecollectionsmapping_delete', 'officecollectionsmapping_import', 'officecollectionsmapping_bulkedit', 'officecollectionsmapping_close',
            'productplanogram_read', 'productplanogram_bulkupdate', 'productplanogram_edit', 'productplanogram_delete', 'productplanogram_column', 'productplanogram_import',
            'planogramexport_read',
            'validateacuityframeimport_read',

            //Frame pick
            'framepick_read', 'framepick_printall', 'framepick_update',
            
            //New Style Kits
            'newstylekits_read',
            'kitmaintenance_read', 'kitmaintenance_add', 'kitmaintenance_edit', 'kitmaintenance_archive', 'kitmaintenance_delete', 'kitmaintenance_clone', 'kitmaintenance_archive',
            'kitprocessing_read', 'kitprocessing_addedit', 'kitprocessing_clone', 'kitprocessing_delete',
            
            //Reports
            'reports_read',
            'framearranger-locationinventorytransfers_read',
            'framearranger-locationonhandinventory_read',
            'framearranger-locationvendorcollection_read',
            'framearranger-replenishmentcp_read',
            'framearranger-replenishmentdct_read',
            'framearranger-replenishmentstocktransfer_read',
            'framearranger-replenishmentvendororder_read',

            //System Preferences
            'systempreferences_read',
            'replenishmentpreferences_read', 'replenishmentpreferences_add', 'replenishmentpreferences_delete', 'replenishmentpreferences_edit',
            
            //Utilities
            'utilities_read',
            'fedexexpressoffices_read', 'expressoffices_add', 'expressoffices_delete',
            'fedexshippinglabels_read', 'fedex_search', 'fedex_close', 'fedex_open', 'fedex_end', 'fedex_scanoffice',
            'openpull_read', 'openpull_load',
            'printlittletags_read', 'printtags_create', 'printtags_archive',
            'vieworders_read', 'orders_load',
            'vendorassignment_read', 'vendor_add',
            'updateorderstatus_read', 'ordersearch_update', 'ordersearch_exclusion',
        ]
    },
    dcstaff:
    {
        scopes: [
            //main menu
            'dashboard_read',

            //Frame pick
            'framepick_read', 'framepick_printall', 'framepick_update',

            //Utilities
            'utilities_read',
            'fedexexpressoffices_read', 'expressoffices_add', 'expressoffices_delete',
            'fedexshippinglabels_read', 'fedex_search', 'fedex_close', 'fedex_open', 'fedex_end', 'fedex_scanoffice',
            'printlittletags_read', 'printtags_create', 'printtags_archive',
        ]
    },
    technical:
    {
        scopes: []
    },
    productstaff: {
        scopes: [
             //main menu
            'dashboard_read',

            //DC replenishment
            'dcreplenishment_read',
            'vendororder_read', 'replenishment_approve', 'replenishment_reject', 'replenishment_refresh', 'replenishment_manualtrigger',
            'vendorreceipt_read', 'vendororders_delete',
            'vendorbackorders_read', 'pendingbackorder_cancel',

            //Frame arranger
            'framearranger_read',
            'vendorstocollectionsmapping_read', 'vendorscollectionsmapping_add', 'vendorscollectionsmapping_edit',
            'assortmentmanagement_read', 'assortmentmanagement_add', 'assortmentmanagement_delete', 'assortmentmanagement_clone', 'assortmentmanagement_import',
            'officeplanograms_read', 'officecollectionsmapping_add', 'officecollectionsmapping_edit', 'officecollectionsmapping_delete', 'officecollectionsmapping_import', 'officecollectionsmapping_bulkedit', 'officecollectionsmapping_close',
            'productplanogram_read', 'productplanogram_bulkupdate', 'productplanogram_edit', 'productplanogram_delete', 'productplanogram_column', 'productplanogram_import',
            'planogramexport_read',
            'validateacuityframeimport_read',

            //New Style Kits
            'newstylekits_read',
            'kitmaintenance_read', 'kitmaintenance_add', 'kitmaintenance_edit', 'kitmaintenance_archive', 'kitmaintenance_delete', 'kitmaintenance_clone', 'kitmaintenance_archive',
            'kitprocessing_read', 'kitprocessing_addedit', 'kitprocessing_clone', 'kitprocessing_delete',
            
            //Reports
            'reports_read',
            'framearranger-locationinventorytransfers_read',
            'framearranger-locationonhandinventory_read',
            'framearranger-locationvendorcollection_read',
            'framearranger-replenishmentcp_read',
            'framearranger-replenishmentdct_read',
            'framearranger-replenishmentstocktransfer_read',
            'framearranger-replenishmentvendororder_read',

            //System Preferences
            'systempreferences_read',
            'replenishmentpreferences_read', 'replenishmentpreferences_add', 'replenishmentpreferences_delete', 'replenishmentpreferences_edit',
        ]
    },
    itadmin: {
        scopes: []
    },
}

export type GenericObject = { [key: string]: string[] };

export const MENU_URL_PERMISSIONS: GenericObject = {
    dashboard_read: ["/dashboard"],
    dcreplenishment_read: ["/replenishment",
        "/vendororders",
        "/pendingbackorder"]
    ,
    framearranger_read: ["/vendorscollectionsmapping",
        "/assortmentmanagement",
        "/officecollectionsmapping",
        "/productplanogram",
        "/validateacuityframeimpor"],
    framepick_read: ["/framepick"],
    newstylekits_read: ["/kitmaintenance",
        "/kitprocessing",
        "/newKit",
        "/processNewKit",
        "/kitDetailPage"]
    ,
    systempreferences_read: ["/replenishmentpreferences"],
    utilities_read: [
        "/expressoffices",
        "/fedex",
        "/openpull",
        "/printtags",
        "/orders",
        "/vendor",
        "/ordersearch"]
}
